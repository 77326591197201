import "./introduction.scss";
import ChangingTitle from "./ChangingTitle";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";

export default function Introduction() {
    return (
        <div className="introduction" id="introduction">
            <div className="section_title">
                <ModeStandbyIcon className="title_icon" />
                <h1>Introduction</h1>
            </div>
            <div className="wrapper">
                <div className="left">
                    <div className="container">
                        <span className="first_name">Taşkın</span>
                        <span className="middle_name">Nehir</span>
                    </div>
                    <span className="last_name">Doğan</span>
                    <div className="seperator"></div>
                    <ChangingTitle />
                </div>
                <div className="right">
                    <p className="introduction_text">
                        Hello, this is my website. Here you can find information
                        about me, my work, my contact information, my resume and
                        a blog.
                    </p>
                </div>
            </div>
        </div>
    );
}
