import "./workexperience.scss";
import WorkIcon from "@mui/icons-material/Work";
import { useState } from "react";
import WorkCard from "./WorkCard";

export default function WorkExperience() {
    const [works] = useState([
        {
            title: "Post-Sales Linux Support Expert Assistant",
            title_date_range: "2022 - Present",
            company: "Profelis IT Consultancy",
            description:
                "My professional experience primarily revolves around Linux and Open-Source projects, detailed as follows:",
            experiences: [
                "Infrastructure Management",
                "Linux System Administration",
                "Operation Squad Activities",
                "Micro Service Architectures",
                "Python Back-End Development",
                "Python GUI Development",
                "Windows Server Administration",
                "One Identity Safeguard for Privileged Sessions",
                "NetIQ Advanced Authentication",
                "Samba",
                "LDAP",
                "Active Directory",
                "Mattermost",
                "MantisBT",
                "Jitsi",
                "GitLab",
                "Zabbix",
                "Zimbra",
                "Unix like systems",
                "Customer Support",
                "Custom Linux Distributions",
                "SambaBox",
                "PassBox",
                "Orca",
                "System Design",
                "Grafana",
                "Prometheus",
                "Ansible",
                "Obsidian",
                "Docker",
                "Kubernetes",
                "KVM/QEMU",
                "HashiCorp Vault",
                "Program Design",
                "MariaDB",
                "PostgreSQL",
                "MySQL",
                "MongoDB",
                "Linux Security",
                "Selenium",
            ].sort(),
        },
        {
            title: "Freelance Software Developer",
            title_date_range: "2019 - 2022",
            description:
                "As a freelance software developer, I have engaged in a diverse array of projects spanning multiple fields, including web development and the development of genetic algorithms for identifying optimal parameters to input into functions at specific time intervals. My freelance experience is detailed as follows:",
            experiences: [
                "Web site trackers",
                "Websites",
                "Mobile apps (React-Native)",
                "Linux development",
                "Terminal applications for personal usage",
                "GUI applications",
                "Security system (facial-recognition)",
                "Project management on that security system mentioned above",
                "Created a new type of secure authentication system with a GUI and a strong encryption that I developed.",
                "System administration for any kind of Linux based server or machine.",
            ].sort(),
        },
        {
            title: "Private Tutor",
            title_date_range: "2016 - 2022",
            description:
                "I have offered private tutoring services in the following areas:",
            experiences: [
                "Mathematics",
                "Physics",
                "Science",
                "English",
                "Python Programming",
                "Database Management",
                "Web Development",
                "Linux",
                "Algoritmic Logic Development",
            ].sort(),
        },
    ]);
    return (
        <div className="work_history">
            <div className="section_title">
                <WorkIcon className="title_icon" />
                <h3 className="title_text">Experience</h3>
            </div>

            <div className="work_experiences">
                {works.map((work) => (
                    <WorkCard
                        title={work.title}
                        title_date_range={work.title_date_range}
                        description={work.description}
                        experiences={work.experiences}
                        company={work.company}
                    />
                ))}
            </div>
        </div>
    );
}
