import { useState } from "react";
import "./interests.scss";
import PaletteIcon from '@mui/icons-material/Palette';
import Interest from "./Interest";
export default function Interests() {
    const [interests] = useState([
        "Learning new technologies",
        "Gaming",
        "Rubik's cubes",
        "Reading",
        "Watching movies",
        "Listening to music",
        "Touch Typing",
        "Cooking",
        "Chess",
        "Archery",
        "Artificial Intelligence",
        "Cartoons",
        "Mathematics",
    ]);
    return (
        <div className="interests">
            <div className="section_title">
                <PaletteIcon className="title_icon" />
                <h3 className="title_text">Interests</h3>
            </div>

            <div className="interests_wrapper">
                {interests.map((interest) => (
                    <Interest
                        interest={interest}
                    />
                ))}
            </div>
        </div>
    );
}
