import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import "./certificate.scss";

export default function Certificate({
    study,
    location,
    institution,
    date_range,
    description,
}) {
    return (
        <div className="certificate">
            <div className="certificate_title">
                <CircleIcon className="bullet_point" />
                <div className="title">
                    <h4>
                        {study}{" "}
                        <span className="institution">{institution}</span>
                    </h4>
                    <h5>
                        <span className="location">{location} </span>
                        {date_range}
                    </h5>
                </div>
            </div>
            <div className="description">
                <p>{description}</p>
            </div>
        </div>
    );
}
