import NotFound from "../../pages/notfound/NotFound";
import WorkPortfolio from "./portfolio_project/WorkPortfolio";
import WorkFRecog from "./f_recog_project/WorkFRecog";

const works_url_handler = (url_path) => {
    if (url_path.endsWith("/portfolio")) {
        return <WorkPortfolio />;
    } else if (url_path.endsWith("/f_recog")) {
        return <WorkFRecog />;
    } else {
        return <NotFound />;
    }
};

export default works_url_handler;
