import React from "react";
import ProgressBar from "../../../../components/progressbar/ProgressBar";
import "./language.scss";

export default function Language({ language, rating }) {
    return (
        <div className="language">
            <div className="language_title">{language}</div>
            <ProgressBar percentage={rating} />
        </div>
    );
}
