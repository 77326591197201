import { useState } from "react";
import "./certificates.scss";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Certificate from "./Certificate";
export default function Certificates() {
    const [certificates] = useState([
        {
            study: "CKS",
            institution: "The Linux Foundation",
            date_range: "2024 - 2026",
            description:
                "I have successfully achieved the Certified Kubernetes Security Specialist (CKS) certification.",
        },
        {
            study: "CKA",
            institution: "The Linux Foundation",
            date_range: "2023 - 2026",
            description:
                "I have successfully achieved the Certified Kubernetes Administrator (CKA) certification.",
        },
        {
            study: "LPIC-1",
            institution: "LPI",
            date_range: "2022 - 2027",
            description:
                "I have successfully qualified for the LPIC-1 Linux Administration certification.",
        },
    ]);
    return (
        <div className="certificates">
            <div className="section_title">
                <WorkspacePremiumIcon className="title_icon" />
                <h3 className="title_text">Certificates</h3>
            </div>

            <div className="certificates_wrapper">
                {certificates.map((certificate) => (
                    <Certificate
                        study={certificate.study}
                        location={certificate.location}
                        institution={certificate.institution}
                        date_range={certificate.date_range}
                        description={certificate.description}
                    />
                ))}
            </div>
        </div>
    );
}
