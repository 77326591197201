import AboutMe from "./pages/aboutme/AboutMe";
import Blog from "./pages/blog/Blog";
import Contact from "./pages/contact/Contact";
import Landing from "./pages/landing/Landing";
import NotFound from "./pages/notfound/NotFound";
import Resume from "./pages/resume/Resume";
import downloadResume from "./pages/resume/downloadResume";
import WorksPage from "./pages/workspage/WorksPage";
import works_url_handler from "./components/works/works_url_handler";

const url_handler = (url_path) => {
    if (url_path === "/") {
        return <Landing />;
    } else if (url_path === "/resume") {
        return <Resume />;
    } else if (url_path === "/works") {
        return <WorksPage />;
    } else if (url_path === "/about") {
        return <AboutMe />;
    } else if (url_path === "/contact") {
        return <Contact />;
    } else if (url_path.startsWith("/work/")) {
        return works_url_handler(url_path);
    } else if (url_path === "/blog") {
        return <Blog />;
    } else if (url_path === "/download_resume") {
        // Create the PDF file from the resume page and download it.
        setTimeout(downloadResume, 100); // Add a small delay to ensure DOM is ready.
        return <Resume />; // Render the resume page before downloading.
    } else {
        return <NotFound />;
    }
};

export default url_handler;
