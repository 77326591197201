import "./languages.scss";
import { useState } from "react";
import Language from "./Language";
import FlagIcon from "@mui/icons-material/Flag";

export default function Languages() {
    const [languages] = useState([
        {
            language: "Turkish",
            rating: 100,
        },
        {
            language: "English",
            rating: 90,
        },
    ]);
    return (
        <div className="languages">
            <div className="languages_title">
                <FlagIcon className="languages_icon" />
                <h3 className="languages_text">Languages</h3>
            </div>
            {languages.map((language) => (
                <Language
                    language={language.language}
                    rating={language.rating}
                />
            ))}
        </div>
    );
}
