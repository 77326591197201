import { useState } from "react";
import "./studies.scss";
import SchoolIcon from "@mui/icons-material/School";
import Study from "./Study";
export default function Studies() {
    const [studies] = useState([
        {
            study: "Mathematics",
            location: "Ankara/Turkey",
            institution: "Ankara University",
            date_range: "2016 - 2019",
            description:
                "I discontinued my studies in mathematics due to the substandard quality of education I encountered.",
        },
        {
            study: "Physics Engineering",
            location: "Ankara/Turkey",
            institution: "Hacettepe University",
            date_range: "2019 - 2021",
            description:
                "I withdrew from my studies in physics engineering due to professional commitments that required my full attention.",
        },
        {
            study: "Udemy",
            description:
                "Throughout my freelancing career, I have avidly pursued a wide range of educational opportunities, successfully completing over 150 courses across various disciplines.",
        },
        {
            study: "Youtube",
            description:
                "Throughout my professional journey, I have demonstrated a commitment to continuous learning, having completed over 200 courses in diverse subjects accessible to me. Additionally, in my personal time, I engage in watching YouTube videos, particularly those focused on cutting-edge technology, as I find great pleasure in staying informed about the latest advancements in the field.",
        },
    ]);
    return (
        <div className="studies">
            <div className="section_title">
                <SchoolIcon className="title_icon" />
                <h3 className="title_text">Studies</h3>
            </div>

            <div className="studies_wrapper">
                {studies.map((study) => (
                    <Study
                        study={study.study}
                        location={study.location}
                        institution={study.institution}
                        date_range={study.date_range}
                        description={study.description}
                    />
                ))}
            </div>
        </div>
    );
}
