import "./menu.scss";

export default function Menu({ menuActive }) {
    return (
        <div className={menuActive ? "menu active" : "menu"}>
            <ul>
                <li>
                    <a href="/">Home</a>
                </li>
                <li>
                    <a href="/works">Works</a>
                </li>
                <li>
                    <a href="/about">About Me</a>
                </li>
                <li>
                    <a href="/contact">Contact</a>
                </li>
                <li>
                    <a href="/resume">Resume</a>
                </li>
                <li>
                    <a href="/blog">Blog</a>
                </li>
            </ul>
        </div>
    );
}
