import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import "./interest.scss";

export default function Interest({
    interest,
}) {
    return (
        <div className="interest">
            <div className="interest_title">
                <CircleIcon className="bullet_point" />
                <div className="title">
                    <h4>{interest}</h4>
                </div>
            </div>
        </div>
    );
}
