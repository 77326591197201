import "./works.scss";
import WorkIcon from "@mui/icons-material/Work";
import { Slide } from "react-slideshow-image";
import portfoliodata from "../../../../components/works/portfolio_project/workdata";
import frecogdata from "../../../../components/works/f_recog_project/workdata";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//* Slide image imports
const slideImages = ["images/portfolio/thumbnail.png"];

const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    prevArrow: (
        <div className="arrow_wrapper prev">
            <ArrowBackIosNewIcon className="works_slide_arrow" />
        </div>
    ),
    nextArrow: (
        <div className="arrow_wrapper next">
            <ArrowForwardIosIcon className="works_slide_arrow" />
        </div>
    ),
};

export default function Works({ menuActive }) {
    return (
        <div className="works" id="works">
            <div className="section_title">
                <WorkIcon className="title_icon" />
                <h1>Works</h1>
            </div>
            <div className="wrapper">
                {!menuActive ? (
                    <Slide {...properties} easing="ease" className="slide">
                        <div className="each-slide">
                            <a href="/work/portfolio">
                                <div
                                    style={{
                                        backgroundImage: `url(${slideImages[0]})`,
                                    }}
                                >
                                    <span>{portfoliodata.work_intro}</span>
                                </div>
                            </a>
                        </div>
                        <div className="each-slide">
                            <a href="/work/f_recog">
                                <div
                                    style={{
                                        backgroundImage: `url(${slideImages[1]})`,
                                    }}
                                >
                                    <span>{frecogdata.work_intro}</span>
                                </div>
                            </a>
                        </div>
                        {/* <div className="each-slide">
                            <div
                                style={{
                                    backgroundImage: `url(${slideImages[1]})`,
                                }}
                            >
                                <span>Slide 2</span>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div
                                style={{
                                    backgroundImage: `url(${slideImages[2]})`,
                                }}
                            >
                                <span>Slide 3</span>
                            </div>
                        </div> */}
                    </Slide>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}
