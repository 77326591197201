import "./topbar.scss";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

export default function Topbar({ menuActive, setMenuActive, middleMenuItems }) {
    return (
        <div className="topbar">
            <div className="left">
                <h1 className="name">
                    <span className="first_name">T</span>
                    <span className="middle_name">N</span>
                    <span className="last_name">D</span>
                </h1>
            </div>
            <div className={menuActive ? "middle disabled" : "middle"}>
                {middleMenuItems.map((item) => item)}
                <h1>Menu</h1>
            </div>
            <div className="right">
                {menuActive ? (
                    <CloseIcon
                        className="menu_icon"
                        onClick={() => {
                            setMenuActive(!menuActive);
                        }}
                    />
                ) : (
                    <MenuIcon
                        className="menu_icon"
                        onClick={() => {
                            setMenuActive(!menuActive);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
