import "./top.scss";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PublicIcon from "@mui/icons-material/Public";

export default function Top() {
    return (
        <div className="top">
            <div className="picture">
                <img src="/images/portre.jpeg" alt="pp" id="picture" />
            </div>
            <div className="wrapper">
                <div className="inner_container">
                    <div className="name">
                        <span className="first_name">Taşkın</span>
                        <span className="middle_name">Nehir</span>
                        <span className="last_name">Doğan</span>
                    </div>

                    <div className="job_title">
                        <h3 className="title">DevOps Engineer</h3>
                    </div>
                </div>
                <div className="contact_info">
                    <div className="info">
                        <PublicIcon className="icon" />
                        <a href="https://www.tndogan.com">
                            <h3 className="info_text">www.tndogan.com</h3>
                        </a>
                    </div>
                    <div className="info">
                        <LinkedInIcon className="icon" />
                        <a href="https://www.linkedin.com/in/tndogan">
                            <h3 className="info_text">
                                www.linkedin.com/in/tndogan
                            </h3>
                        </a>
                    </div>
                    <div className="info">
                        <EmailIcon className="icon" />
                        <a href="mailto:tnehirdogan@gmail.com">
                            <h3 className="info_text">tnehirdogan@gmail.com</h3>
                        </a>
                    </div>
                    <div className="info">
                        <LocalPhoneIcon className="icon" />
                        <h3 className="info_text">+90 (545) 177 25 26</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
