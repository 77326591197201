import React, { Component } from "react";
import { init } from "ityped";

export default class ChangingTitle extends Component {
    componentDidMount() {
        const myElement = document.querySelector("#myElement");
        init(myElement, {
            showCursor: false,
            strings: [
                "Software Developer",
                "Programmer",
                "Back-End Developer",
                "Full-Stack Developer",
                "Linux System Administrator",
            ],
        });
    }
    render() {
        return <div id="myElement" className="title"></div>;
    }
}
