import "./skills.scss";
import { useState } from "react";
import Skill from "./Skill";
import ExtensionIcon from "@mui/icons-material/Extension";

export default function Skills() {
    const [skills] = useState([
        {
            skill: "Python",
            rating: 95,
        },
        {
            skill: "Linux",
            rating: 95,
        },
        {
            skill: "Rust",
            rating: 80,
        },
        {
            skill: "PHP",
            rating: 80,
        },
        {
            skill: "C/C++",
            rating: 40,
        },
        {
            skill: "GO Lang",
            rating: 20,
        },
        {
            skill: "Shell Scripting",
            rating: 90,
        },
        {
            skill: "TyeScript",
            rating: 85,
        },
        {
            skill: "HTML",
            rating: 80,
        },
        {
            skill: "CSS",
            rating: 85,
        },
        {
            skill: "Linux Network",
            rating: 90,
        },
        {
            skill: "React",
            rating: 75,
        },
        {
            skill: "Container Technologies",
            rating: 90,
        },
        {
            skill: "Kubernetes",
            rating: 90,
        },
        {
            skill: "Large Language Models",
            rating: 80,
        },
        {
            skill: "Research Skills",
            rating: 95,
        },
        {
            skill: "ChatGPT",
            rating: 95,
        },
        {
            skill: "Machine Learning",
            rating: 75,
        },
        {
            skill: "Data Science",
            rating: 70,
        },
        {
            skill: "DevOps",
            rating: 80,
        },
        {
            skill: "Database Management",
            rating: 65,
        },
    ]);

    // sort skills by rating from highest to lowest
    skills.sort((a, b) => b.rating - a.rating);

    return (
        <div className="skills">
            <div className="skills_title">
                <ExtensionIcon className="skills_icon" />
                <h3 className="skills_text">Skills</h3>
            </div>
            {skills.map((skill) => (
                <Skill skill={skill.skill} rating={skill.rating} />
            ))}
        </div>
    );
}
