import "./resume.scss";
import Top from "./components/top/Top";
import Summary from "./components/summary/Summary";
import WorkExperience from "./components/workexperience/WorkExperience";
import Studies from "./components/studies/Studies";
import Certificates from "./components/certificates/Certificates";
import Interests from "./components/interests/Interests";
import Skills from "./components/skills/Skills";
import Languages from "./components/languages/Languages";

export default function Resume() {
    return (
        <div className="resume">
            <Top />
            <div className="wrapper">
                <div className="left_panel">
                    <Skills />
                    <Languages />
                </div>
                <div className="main">
                    <Summary />
                    <WorkExperience />
                    <Studies />
                    <Certificates />
                    <Interests />
                </div>
            </div>
        </div>
    );
}
