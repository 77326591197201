import "./progressbar.scss";

export default function ProgressBar({ percentage }) {
    return (
        <div className="progressbar chart">
            <div
                className="bar"
                style={{
                    width: `${percentage}%`,
                }}
            ></div>
        </div>
    );
}
