import html2canvas from "html2canvas";
import jsPDF from "jspdf";


export default function downloadResume() {
    // Find the resume container element by ID or class name.
    const resumeElement = document.getElementsByClassName("resume")[0];
    if (!resumeElement) {
        console.error("Resume content not found!");
        return;
    }

    // Use html2canvas to capture the resume content and generate a canvas.
    html2canvas(resumeElement, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [canvas.width * 0.264583, canvas.height * 0.264583],
        }); // Custom size to match canvas dimensions
        const imgWidth = canvas.width * 0.264583;
        const imgHeight = canvas.height * 0.264583;

        // Add image to PDF and save it.
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("resume.pdf");

        // Redirect user to /resume after a delay to ensure download completes.
        setTimeout(() => {
            window.location.href = "/resume";
        }, 1000);
    });
}