import React from "react";
// import CircleIcon from "@mui/icons-material/Circle";
import ProgressBar from "../../../../components/progressbar/ProgressBar";
import "./skill.scss";

export default function Skill({ skill, rating }) {
    return (
        <div className="skill">
            <div className="skill_title">{skill}</div>
            <ProgressBar percentage={rating} />
        </div>
    );
}
