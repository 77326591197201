import "./landing.scss";
import Topbar from "../../components/topbar/Topbar";
import Introduction from "./sections/introduction/Introduction";
import Works from "./sections/works/Works";
import AboutMe from "./sections/aboutme/AboutMe";
import Contact from "./sections/contact/Contact";
import Menu from "../../components/menu/Menu";
import { useState } from "react";

export default function Landing() {
    const hash = window.location.hash;
    const [activeItem, setActiveItem] = useState(
        hash ? hash.slice(1) : "introduction"
    );
    const [menuActive, setMenuActive] = useState(false);
    const topBarItems = [
        <a
            href="#introduction"
            className={activeItem === "introduction" ? "active" : ""}
            onClick={() => setActiveItem("introduction")}
        >
            Introduction
        </a>,
        <a
            href="#works"
            className={activeItem === "works" ? "active" : ""}
            onClick={() => setActiveItem("works")}
        >
            Works
        </a>,
        <a
            href="#about"
            className={activeItem === "about" ? "active" : ""}
            onClick={() => setActiveItem("about")}
        >
            About Me
        </a>,
        <a
            href="#contact"
            className={activeItem === "contact" ? "active" : ""}
            onClick={() => setActiveItem("contact")}
        >
            Contact
        </a>,
    ];

    return (
        <div className="landing">
            <Topbar
                menuActive={menuActive}
                setMenuActive={setMenuActive}
                middleMenuItems={topBarItems}
            />
            <Menu menuActive={menuActive} />
            <div className="sections">
                <Introduction />
                <Works menuActive={menuActive} />
                <AboutMe />
                <Contact />
            </div>
        </div>
    );
}
