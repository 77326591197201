import "./workcard.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

export default function WorkCard({
    title,
    title_date_range,
    description,
    experiences,
    company,
}) {
    return (
        <div className="work_card">
            <div className="work_title">
                <ArrowForwardIosIcon className="bullet_point" />
                <div className="title">
                    <h4>{title}</h4>
                    {/* If there is company therefore print it in an h5 tag */}
                    <div className="subinfo">
                        {company && <h5>{company}</h5>}
                        <h5>{title_date_range}</h5>
                    </div>
                </div>
            </div>
            <div className="description">
                <p>{description}</p>
            </div>
            <div className="experiences">
                {experiences.map((experience) => (
                    <div className="experience">
                        <div className="prompt">
                            <DoubleArrowIcon className="bullet_point" />
                        </div>
                        <h3>{experience}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}
