import "./blog.scss";
import Topbar from "../../components/topbar/Topbar";
import Menu from "../../components/menu/Menu";
import { useState } from "react";

export default function Blog() {
    const [menuActive, setMenuActive] = useState(false);
    const [activeItem, setActiveItem] = useState("work1");
    const topBarItems = [
        // <a
        //     href="#work1"
        //     className={activeItem === "work1" ? "active" : ""}
        //     onClick={() => setActiveItem("work1")}
        // >
        //     Work 1
        // </a>,
        // <a
        //     href="#work2"
        //     className={activeItem === "work2" ? "active" : ""}
        //     onClick={() => setActiveItem("work2")}
        // >
        //     Work 2
        // </a>,
        // <a
        //     href="#work3"
        //     className={activeItem === "work3" ? "active" : ""}
        //     onClick={() => setActiveItem("work3")}
        // >
        //     Work 3
        // </a>,
        // <a
        //     href="#work4"
        //     className={activeItem === "work4" ? "active" : ""}
        //     onClick={() => setActiveItem("work4")}
        // >
        //     Work 4
        // </a>,
    ];
    return (
        <div className="blog">
            <Topbar
                menuActive={menuActive}
                setMenuActive={setMenuActive}
                middleMenuItems={topBarItems}
            />
            <Menu menuActive={menuActive} />
            <div className="sections">
                <h1>Blog</h1>
            </div>
        </div>
    );
}
