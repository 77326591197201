import "./aboutme.scss";
import PersonIcon from "@mui/icons-material/Person";

export default function AboutMe() {
    return (
        <div className="aboutme" id="about">
            <div className="section_title">
                <PersonIcon className="title_icon" />
                <h1>About Me</h1>
            </div>
            <div className="wrapper">
                <div className="left">
                    <div className="photo_container">
                        <img src="/images/portre.jpeg" alt="pp" id="photo" />
                    </div>
                </div>
                <div className="right">
                    <p className="aboutme_text">
                        I've been coding since I was a child. I started it as a
                        hobby and now I write code professionally. If we count
                        my unprofessional coding past in my journey I have
                        around 13 years of experience.
                    </p>
                </div>
            </div>
        </div>
    );
}
