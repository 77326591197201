import "./summary.scss";
import AdjustIcon from "@mui/icons-material/Adjust";

export default function Summary() {
    return (
        <div className="summary">
            <div className="section_title">
                <AdjustIcon className="title_icon" />
                <h3 className="title_text">Professional Summary</h3>
            </div>
            <p className="summary_text">
                Dedicated and resourceful Post-Sales Linux Support Expert Assistant with 6 years of experience in the tech industry, combining strong skills in Linux system management, Python scripting, and cloud technologies including Kubernetes and Docker. My portfolio includes the development of custom Linux distributions and an innovative Python-based LDAP and Zimbra integration tool. I am known for my detail-oriented and creative approach to problem-solving, which has been a driving force in my career.
            </p>
            <br/>
            <p className="summary_text">
                My educational background is diverse and self-directed, with a focus on continuous learning through over 350 online courses, complemented by LPIC-1 and CKA certifications. I have also developed leadership skills through effectively managing a small team during my freelance career. Currently seeking a DevOps engineer role, I am eager to apply my analytical abilities and growing communication skills in a challenging environment that offers opportunities for project management and team development.
            </p>
        </div>
    );
}
